import { Component, OnInit } from '@angular/core';
import {AppStateService} from '../../services/app-state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  phoneNumber: string;
  phoneNumberLink: string;

  constructor(private _app: AppStateService) {
    this.phoneNumber = this._app.phoneNumber;
    this.phoneNumberLink = this._app.phoneNumberLink;
  }

  ngOnInit() {
  }

}
