import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  endpoint = environment.leadEndPoint;

  constructor(private _http: HttpClient) { }

  public getUserSession() {
    try {
      return this._http.get(this.endpoint + '/api/getSessionId');
    } catch (e) {
      console.log(e);
    }
  }
}
