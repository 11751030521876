import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsUseComponent } from './terms-use/terms-use.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import {SessionService} from './shared/services/session.service';
import {LeadService} from './shared/services/lead.service';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import {NgxMaskModule} from 'ngx-mask';
import { NotFoundComponent } from './not-found/not-found.component';
import { ChallangeComponent } from './challange/challange.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ThankYouComponent,
    PrivacyComponent,
    TermsUseComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    ChallangeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    HttpClientModule,
  ],
  providers: [SessionService, SessionService, LeadService, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {}

