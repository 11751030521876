import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-use',
  templateUrl: './terms-use.component.html'
})
export class TermsUseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
