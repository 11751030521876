import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Lead} from '../shared/Models/lead';
import {AppStateService} from '../shared/services/app-state.service';
import {LeadService} from '../shared/services/lead.service';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute} from '@angular/router';
// @ts-ignore
import eProviders from '../../assets/utilities.json';
import eMalls from '../../assets/malls.json';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styles: []
})
export class LandingPageComponent implements OnInit {
  options: string[] = eProviders;
  malls: string[] = eMalls;
  firstNameInValid = false;
  lstNameInValid = false;
  electricityProviderInValid = false;
  emailInvalid = false;
  phoneNumberInvalid = false;
  addressInvalid = false;
  zipCodeInvalid = false;
  stateInvalid = false;
  displayForm = false;
  jornaya: string;
  formUrl = 'false';
  @ViewChild('formView', { static: false }) MyProp: ElementRef;

  nameRegex = new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/);
  phoneRegex = new RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/);
  emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  streetRegex = new RegExp(/^(\d{3,})\s?(\w{0,5})\s([a-zA-Z]{2,30})\s([a-zA-Z]{2,15})\.?\s?(\w{0,5})$/);
  lead = new Lead();

  constructor(
    private _app: AppStateService,
    private _lead: LeadService,
    private _cookieService: CookieService,
    private _route: ActivatedRoute) {
    this.lead = this._app.lead;
    this.lead.leadData.monthlyBill = '$0 - $50';
    this.lead.leadData.shade = 'A little bit';
    this.formUrl = this._route.snapshot.queryParamMap.get('form') || 'false';
    this.lead.leadData.creditGrade = 'Excellent';
    if (this.formUrl === 'true') {
      this.showForm();
    }
  }

  ngOnInit() {
    this.jornaya = this._cookieService.get('leadid_token-B04E3B3A-5ADD-35D0-8BCD-15234DDCEBBC-230FF02A-8645-DD23-EA26-26C67022758C');
  }

  validateZipCode() {
    if (this.lead.geoLocation.zipcode.length === 5) {
      this.zipCodeInvalid = false;
    } else {
      this.zipCodeInvalid = true;
    }
  }

  validateState() {
    if (this.lead.geoLocation.zipcode.length >= 0) {
      this.stateInvalid = false;
    } else {
      this.stateInvalid = true;
    }
  }

  validateAddress() {
    if (this.lead.leadData.address.length >= 5) {
      this.addressInvalid = false;
    } else {
      this.addressInvalid = true;
    }
  }

  validatePhoneNumber() {
    if (this.lead.leadData.phoneNumber.length >= 7) {
      if (this.phoneRegex.test(this.lead.leadData.phoneNumber)) {
        this.phoneNumberInvalid = false;
      } else {
        this.phoneNumberInvalid = true;
      }
    } else {
      this.phoneNumberInvalid = true;
    }
  }

  validateElectricityProvider() {
    if (this.lead.leadData.provider.length >= 3) {
      this.electricityProviderInValid = false;
    } else {
      this.electricityProviderInValid = true;
    }
  }

  validateFirstName() {
    if (this.lead.leadData.firstName.length >= 3) {
      if (this.nameRegex.test(this.lead.leadData.firstName)) {
        this.firstNameInValid = false;
      } else {
        this.firstNameInValid = true;
      }
    } else {
      this.firstNameInValid = true;
    }
  }

  validateLastName() {
    if (this.lead.leadData.lastName.length >= 3) {
      if (this.nameRegex.test(this.lead.leadData.lastName)) {
        this.lstNameInValid = false;
      } else {
        this.lstNameInValid = true;
      }
    } else {
      this.lstNameInValid = true;
    }
  }

  validateEmailAdddress() {
    if (this.lead.leadData.email.length >= 5) {
      if (this.emailRegex.test(this.lead.leadData.email)) {
        this.emailInvalid = false;
      } else {
        this.emailInvalid = true;
      }
    } else {
      this.emailInvalid = true;
    }
  }

  showForm() {
    this.displayForm = !this.displayForm;
    setTimeout(() => {
      this.delayDisplayForm();
    }, 500);
  }

  delayDisplayForm() {
    window.scrollTo(0, (document.body.scrollHeight - 250));
  }

  validateForm() {
    // this.validateEmailAdddress();
    this.validateLastName();
    this.validateFirstName();
    // this.validateElectricityProvider();
    this.validatePhoneNumber();
    // this.validateAddress();
    this.validateZipCode();
    if (
    // this.lead.leadData.address.length > 0
    //   && this.lead.leadData.email.length > 0
      this.lead.leadData.firstName.length > 0
      && this.lead.leadData.lastName.length > 0
      && this.lead.leadData.phoneNumber.length > 0
      && this.lead.geoLocation.zipcode.length > 0
      // && this.lead.leadData.provider.length > 0
      && !this.firstNameInValid
      && !this.lstNameInValid
      // && !this.electricityProviderInValid
      && !this.emailInvalid
      // && !this.addressInvalid
      && !this.phoneNumberInvalid
      // && !this.addressInvalid
      && !this.zipCodeInvalid
    ) {
      this.lead.leadContext.jornayaToken = this.jornaya;
      this._app.lead = this.lead;
      // console.log(this._app.lead);
      this._lead.saveLead();
    }
  }

}
